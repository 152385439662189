import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      status: 0,
      content: {},
      feedback: {}
    },
    getters: {
      content: state => state.content,
      feedback: state => state.feedback,
      status: state => state.status
    },
    mutations: {
      set_content ( state, payload ) {
        state.content = payload;
      },
      set_feedback( state, payload ) {
        state.feedback = payload;
      },
      set_status( state, payload ) {
        state.status = payload;
      }
    },
    actions: {
      async fetch_content({commit}, language) {
        await axios.get('https://cx.api.email-content.app.cloud.finnair.com/get/content/' + language
        ).then(response => {
          commit('set_content', response.data)
          commit('set_status', 1)
        })
        .catch(response => commit('set_status', -1))
      },

      async send_feedback({commit}, feedback_content) {
        commit('set_status', 0)
        await axios.post('https://cx.api.email-content.app.cloud.finnair.com/send/feedback', feedback_content
        ).then(response => {
          if(response.data['status']) {
            commit('set_status', 2)
            console.log(response.data)
          } else {
            commit('set_status', -1)
            console.log(response.data)
          }
        }).catch(response => commit('set_status', -1))
      }

    },
})